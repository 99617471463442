.footerLinkHeadContainer {
  background-size: cover;

  height: 50vh;
  width: 100%;
  position: relative;
}

/* header background img */
.storyContainer {
  background-image: url("/src/assets/icons/kapak.jpg");
  background-position: bottom;
}
.brandContainer {
  background-image: url("/src/assets/icons/kapak-2.jpg");
  background-position: 100% 60%;
}
.newsContainer {
  background-image: url("/src/assets/icons/new-in.jpg");
  background-position: center;
}
.awardsContainer {
  background-image: url("/src/assets/icons/kapak-2.jpg");
  background-position: center;
}
.catalogContainer {
  background-image: url("/src/assets/icons/product-catalog.jpg");
  background-position: center;
}
.conditionsContainer {
  background-image: url("/src/assets/icons/conditions.jpg");
  background-position: center;
}
.termUseContainer {
  background-image: url("/src/assets/icons/product-catalog.jpg");
  background-position: center;
}
.privacyContainer {
  background-image: url("/src/assets/icons/product-catalog.jpg");
  background-position: center;
}
.mapContainer {
  background-image: url("/src/assets/icons/site-map.jpg");
  background-position: center;
}
.becomeContainer {
  background-image: url("/src/assets/icons/wholesale.jpg");
  background-position: center;
}

.footerLinkHeadTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.footerLinkHeadText {
  color: #fff;
  font-size: 56px;
  text-shadow: 2px 2px #000;
}

@media (max-width: 1550px) and (min-width: 1300px) {
  .footerLinkHeadText {
    font-size: 48px;
  }
}

@media (max-width: 1299px) and (min-width: 1100px) {
  .footerLinkHeadText {
    font-size: 40px;
  }
}

@media (max-width: 1099px) and (min-width: 900px) {
  .footerLinkHeadText {
    font-size: 30px;
  }
}

@media (max-width: 899px) {
  .footerLinkHeadText {
    margin-top: 35px;
    font-size: 25px;
  }
  .footerLinkHeadContainer {
    height: 30vh;
  }
}
