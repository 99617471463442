.aboutBodyContainer {
  padding: 20px;
}

.aboutBodyText {
  font-size: 19px;
  color: #000;
  text-align: justify;
}
.aboutImg {
  margin: 0 20px;
}

@media (max-width: 1600px) and (min-width: 1400px) {
  .aboutBodyContainer {
    padding: 0px;
  }
  .aboutBodyText {
    font-size: 17px;
  }
}
@media (max-width: 1399px) and (min-width: 1000px) {
  .aboutBodyContainer {
    padding: 0px;
  }
  .aboutBodyText {
    font-size: 15px;
  }
}

@media (max-width: 999px) {
  .aboutBodyContainer {
    padding: 0px;
  }
  .aboutBodyText {
    font-size: 14px;
  }
}
