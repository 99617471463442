.infosContainer {
  padding-top: 2%;
}
@media (min-width: 1601px) {
  .infosContainer {
    padding: 0 170px;
  }
  .highContainer {
    max-width: 100%;
  }
  .itemContainer.itemContainer {
    width: 90%;
    height: 200px;
  }
  .itemContainer:hover.itemContainer {
    width: 108%;
    height: 250px;

    margin: 0;
  }
}
@media (max-width: 1600px) and (min-width: 1531px) {
  .infosContainer {
    padding: 50px 65px;
  }
  .highContainer {
    max-width: 100%;
  }
}
@media (max-width: 1530px) and (min-width: 1300px) {
  .infosContainer {
    padding: 50px 12px;
  }
  .highContainer {
    max-width: 100%;
  }
}
@media (max-width: 1299px) and (min-width: 1000px) {
  .infosContainer {
    padding: 50px 50px;
  }
  .highContainer {
    max-width: 100%;
  }
}
.itemContainer {
  background-color: black;
  cursor: pointer;
  opacity: 0.5;
  padding: 25px 20px;
  margin: 30px;
  width: 95%;
  height: 200px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .itemContainer {
    width: 80%;
    height: 150px;
    margin: 30px;
  }
  .itemContainer .infoText {
    font-size: 16px;
  }
  .itemContainer .firsText {
    font-size: 17px;
  }

  .itemContainer:hover.itemContainer {
    width: 100%;
    height: 200px;

    margin: 0;
  }
}
@media (max-width: 1199px) and (min-width: 990px) {
  .itemContainer {
    width: 75%;
    height: 150px;
  }
  .itemContainer .infoText {
    font-size: 14px;
  }
  .itemContainer .firsText {
    font-size: 16px;
  }

  .itemContainer:hover.itemContainer {
    width: 98%;
    height: 200px;

    margin: 0;
  }
}
@media (max-width: 989px) {
  .itemContainer {
    padding: 25px 20px;
    margin: 10px auto;
    width: 70%;
    height: 150px;
    align-items: center;
    justify-content: center;
  }
  .itemContainer .infoText {
    font-size: 16px;
  }
  .itemContainer .firsText {
    font-size: 16px;
  }
  .itemContainer:hover.itemContainer {
    width: 70%;
    height: 150px;
    padding: 25px 20px;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }
}
.itemContainer:hover {
  width: 110%;
  height: 250px;
  padding: 40px;
  margin: 10px 10px;
}

.itemContainer:hover .icon {
  font-size: 46px;
}
.firsText {
  font-size: 24px;
  color: #fff;
  text-align: center;
}
.infoText {
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin-top: -16px;
  transition: all ease 0.5s;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.icon {
  font-size: 36px;
  color: #fff;
  margin-bottom: -8px;
  transition: all ease 0.5s;
}
.itemImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemImage {
  height: 40px;
  width: 40px;
  transition: all ease 0.5s;
}
.itemContainer:hover .itemImage {
  height: 50px;
  width: 50px;
}

.modalMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalMapImage {
  width: 750px;
  height: auto;
}
@media (max-width: 1600px) and (min-width: 1501px) {
  .modalMapImage {
    width: 650px;
  }
}

@media (max-width: 1500px) and (min-width: 1000px) {
  .modalMapImage {
    width: 500px;
  }
}
@media (max-width: 999px) {
  .modalMapImage {
    width: 100%;
  }
}
