.privacyBodyContainer {
  padding: 20px;
}
.privacyHeaderText {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.privacyBodyText {
  font-size: 19px;
  color: #000;
  text-align: justify;
}

.privacyMadde {
  margin-top: 10px;
}

@media (max-width: 1600px) and (min-width: 1400px) {
  .privacyBodyContainer {
    padding: 0px;
  }
  .privacyHeaderText {
    font-size: 18px;
  }
  .privacyBodyText {
    font-size: 17px;
  }
}
@media (max-width: 1399px) and (min-width: 1000px) {
  .privacyBodyContainer {
    padding: 0px;
  }
  .privacyHeaderText {
    font-size: 18px;
  }
  .privacyBodyText {
    font-size: 16px;
  }
}

@media (max-width: 999px) {
  .privacyBodyContainer {
    padding: 0px;
  }
  .privacyHeaderText {
    font-size: 17px;
  }
  .privacyBodyText {
    font-size: 15px;
  }
}
