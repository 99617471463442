.flamefieldContainer {
  background-image: url("../../../assets/icons/flamefield.jpg");
  background-size: cover;
  background-position: center;
  height: 50vh;
  width: 100%;
  position: relative;
}

.flamefieldTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.flamefieldText {
  color: #fff;
  font-size: 56px;
}

@media (max-width: 1550px) and (min-width: 1300px) {
  .flamefieldText {
    font-size: 48px;
  }
}

@media (max-width: 1299px) and (min-width: 1100px) {
  .flamefieldText {
    font-size: 40px;
  }
}

@media (max-width: 1099px) and (min-width: 900px) {
  .flamefieldText {
    font-size: 30px;
  }
}

@media (max-width: 899px) {
  .flamefieldText {
    margin-top: 35px;
    font-size: 30px;
  }
  .flamefieldContainer {
    height: 30vh;
  }
}
