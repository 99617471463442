.footerContainer {
  background-color: rgba(222, 225, 228, 0.76);
}
.appImg {
  height: auto;
  width: 280px;
  position: absolute;
  top: -170px;
  right: -35px;
}
.footerBoldText {
  font-weight: 700;
}

@media (min-width: 1661px) {
  .footerContainer.footerContainer {
    padding: 0 190px;
  }
  .highFooterContainer {
    max-width: 100%;
  }
}
@media (max-width: 1299px) and (min-width: 1000px) {
  .footerContainer.footerContainer {
    padding: 0 80px;
  }
  .highFooterContainer.highFooterContainer {
    max-width: 100%;
  }
  .appImg.appImg {
    height: auto;
    width: 180px;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .appImg.appImg {
    position: absolute;
    width: 180px;
    top: -120px;
    right: -25px;
  }
  .headerCenter.headerCenter {
    padding-left: 20px;
  }
}
@media (max-width: 999px) and (min-width: 826px) {
  .footerContainer {
    padding: 4px 50px;
  }
  .appImg.appImg {
    width: 160px;
    height: auto;
    position: absolute;
    top: -110px;
    right: -20px;
  }
  .headerCenter.headerCenter {
    padding-left: 0px;
  }
}
@media (max-width: 1600px) and (min-width: 1200px) {
  .footerContainer {
    padding: 4px 0;
  }
  .headerCenter.headerCenter {
    padding-left: 80px;
  }
  .colContainerHeader.colContainerHeader {
    font-size: 22px;
    font-weight: 500;
  }
  .colContainerEmpty.colContainerEmpty {
    margin-bottom: 55px;
  }
  .addressText.addressText {
    font-size: 13px;
  }
  .colBodyText.colBodyText {
    font-size: 14px;
  }
  .appImg.appImg {
    height: auto;
    width: 220px;
    position: absolute;
    top: -140px;
    right: -30px;
  }

  .boranka.boranka {
    width: 120px;
    margin-right: 15px;
  }
  .face.face {
    font-size: 41px;
    margin: auto 6px auto 0px;
  }
  .tiwit.tiwit {
    font-size: 45px;
    margin: auto 6px;
  }
  .pint.pint {
    font-size: 40.5px;
    margin: auto 6px;
  }
  .insta.insta {
    font-size: 44.5px;
    margin: auto 6px;
  }
  .youtube.youtube {
    font-size: 50.5px;
    margin: auto 0 auto 6px;
  }
}

@media (max-width: 825px) {
  .footerContainer {
    padding: 4px 0;
  }
  .company,
  .pRange,
  .helpful {
    display: none;
  }
  .b2b {
    height: 250px;
    margin-top: 20px;
  }
  .mobilB2B.mobilB2B {
    display: block;
  }

  .colContainerHeader.colContainerHeader {
    font-size: 24px;
    font-weight: 500;
  }
  .boranka.boranka {
    height: 31px;
    width: auto;
    margin-right: 10px;
  }
  .appImg.appImg {
    width: 170px;
    height: auto;
    position: absolute;
    top: -100px;
    left: -15px;
  }
}
.borderContainer {
  border-top: 2px solid #000;
}
.text {
  text-align: center;
}
.colContainerEmpty {
  margin-bottom: 63px;
}
.colContainerHeader {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}
.headerCenter {
  padding-left: 120px;
}
.colContainer {
  display: flex;
}
.colContainerBody {
  position: relative;
}
.appContainer {
  position: absolute;
  bottom: 20px;
  right: -48px;
}

.col {
  align-items: left;
}

.socialContainer {
  display: flex;
}

.footerIcon {
  font-size: 25px;
  margin-top: 10px;
  margin-right: 7px;
}
.addressText {
  font-size: 15px;
}
.telText {
  margin-left: 5px;
  padding-top: 8px;
  font-size: 16px;
}
.socialIcon {
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease 0.3s;
}
.face {
  font-size: 49.5px;
  margin: auto 6px auto 0px;
}
.tiwit {
  font-size: 55px;
  margin: auto 6px;
}
.pint {
  font-size: 48px;
  margin: auto 6px;
}
.insta {
  font-size: 53.5px;
  margin: auto 6px;
}
.youtube {
  font-size: 61.5px;
  margin: auto 0 auto 6px;
}
.mobilB2B {
  display: none;
}
.mobilb2bBody {
  height: 50px !important;
}
.colBodyText {
  font-size: 15px;
  cursor: pointer;
  color: #000;
}
.colBodyText:hover {
  text-decoration: underline;
}
.footerC {
  padding: 0px;
  margin: 5px auto;
  position: relative;
}
@media (max-width: 580px) {
  .footerC {
    padding: 0 20px;
  }
}
.boranka {
  width: 150px;
}

/* hover */
.pint:hover {
  color: #e60023;
}
.youtube:hover {
  color: #ff0000;
}
.insta:hover {
  color: #e1306c;
}
.tiwit:hover {
  color: #1da1f2;
}
.face:hover {
  color: #1877f2;
}
