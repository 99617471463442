.headerText {
  font-size: 48px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.rangeHeadContainer {
  margin: 0;
}
.borderTop {
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid #000;
}
.imgRange {
  height: 680px;
  object-fit: cover;
}
.carouselWeb {
  display: block;
}
.carouselMobile {
  display: none;
}
.rangeDeneme {
  position: relative;
}
.rangeRow {
  margin: -4px 0 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.second {
  cursor: pointer;
  height: 684px;
  border-right: 4px solid #ffff;
  position: relative;
  transition: all ease 0.5s;
}
.grNext {
  color: #ffffff;
  font-size: 100px;
}
.nextLabel {
  position: absolute;
  right: 0;
}
.prevLabel {
  position: absolute;
  left: 0;
}

.rangeTextContainer {
  position: absolute;
  top: 520px;
  transition: all ease 0.5s;
}
.rangeName {
  font-size: 45px;
  color: #ffff;
  font-weight: 600;
  text-shadow: 2px 2px #000;
}
.rangeNamee {
  font-size: 45px;
  color: #ffff;
  font-weight: 600;
  letter-spacing: 3.2px;
  text-shadow: 2px 2px #000;
}
.discoverText {
  font-size: 40px;
  color: #ffff;
  font-weight: 900;
  padding-left: 10px;
  margin-top: 150px;
  transition: all ease 0.5s;
  cursor: pointer;
  text-shadow: 2px 2px #000;
}
.discoverIcon {
  color: #000;
  font-size: 60px;
  border-radius: 50%;
  background-color: #fff;
}

.fullClick {
  display: flex;
  width: 100%;
  height: 100%;
}

/* second hover */
.second:hover .discoverText {
  margin-top: 25px;
}
.second:hover .rangeTextContainer {
  position: absolute;
  top: 380px;
}

.carousel-control-prev {
  width: auto !important;
}
.carousel-control-next {
  width: 1px !important;
}

@media (max-width: 1600px) and (min-width: 1000px) {
  .headerText {
    font-size: 44px;
  }
  .imgRange {
    height: 480px;
  }
  .second {
    height: 484px;
  }
  .grNext {
    font-size: 80px;
  }
  .rangeName,
  .rangeNamee {
    font-size: 35px;
  }
  .discoverText {
    font-size: 35px;
    font-weight: 700;
    margin-top: 150px;
  }
  .rangeTextContainer {
    top: 350px;
  }
  .discoverIcon {
    font-size: 50px;
  }
  .second:hover.second.discoverText {
    margin-top: 20px;
  }
  .second:hover.second .rangeTextContainer {
    top: 250px;
  }
}

@media (max-width: 999px) {
  .headerText {
    font-size: 35px;
  }
  .carouselMobile {
    display: block;
  }
  .carouselWeb {
    display: none;
  }
  .grNextMobile {
    color: #ffffff;
    font-size: 50px;
  }
  .imgRangeMobile {
    object-fit: cover;
    object-position: center center;
    height: 310px;
  }
  .rangeRowMobile {
    margin: -4px 0 0 4px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .secondMobile {
    position: relative;
  }
  .rangeTextContainerMobile {
    position: absolute;
    bottom: 20px;
  }
  .rangeNameMobile {
    font-size: 35px;
    color: #ffff;
    text-shadow: 2px 2px #000;
  }
  .discoverTextMobile {
    font-size: 35px;
    color: #ffff;

    padding-left: 10px;

    cursor: pointer;
    text-shadow: 2px 2px #000;
  }
  .discoverIconMobile {
    color: #000;
    font-size: 40px;
    border-radius: 50%;
    background-color: #fff;
  }
}
