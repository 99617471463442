.categoryContainer {
  position: sticky;
  top: 100px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.categoriesHeaderText {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.subElementContainer {
  margin-left: 20px;
  margin-top: -20px;
}
.subElementTextContainer {
  margin-bottom: 5px;
}
.subElementText {
  color: #000;
  font-size: 18px;
}

.categoriesDropdown {
  display: flex;
  justify-content: center;
  position: relative;
}
.mobileElementContainer {
  padding-left: 10px;
}
.mobileHeaderText {
  color: #000;
  font-size: 17px;
  font-weight: 600;
}
.mobileElementText {
  color: #000;
  font-size: 16px;
  padding-left: 25px;
  margin-top: -5px;
}
