.inputSearchContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #fff;
}
.inputSearch {
  width: 70%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  height: 50px;
  margin-right: 5px;
  font-size: 35px;
}
.serachButton {
  width: 8%;
  height: 50px;
  outline: none;
  font-size: 25px;
  border: 1px solid rgb(17, 160, 226);
  background-color: #fff;
  color: rgb(17, 160, 226);
  transition: all ease 0.5s;
}
.serachButton:hover {
  background-color: rgb(17, 160, 226);
  color: #fff;
}
@media (max-width: 1600px) and (min-width: 1226px) {
  .inputSearchContainer {
    height: 140px;
  }
  .inputSearch {
    height: 40px;

    font-size: 25px;
  }
  .serachButton.serachButton {
    font-size: 20px;
  }
}
@media (max-width: 1225px) and (min-width: 901px) {
  .inputSearchContainer {
    height: 120px;
  }
  .serachButton.serachButton {
    font-size: 18px;
  }
  .inputSearch {
    height: 40px;

    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .inputSearchContainer {
    height: 120px;
    width: 100%;
    padding: 0 10px;
    justify-content: start;
    align-items: center;
  }
  .inputSearch {
    width: 80%;
    border-bottom: 1px solid #ccc;
    height: 35px;

    font-size: 18px;
  }
  .serachButton {
    height: 35px;
    width: 20%;
    font-size: 17px;
  }
}
