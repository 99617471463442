.componentBodyContainer {
  margin-bottom: 100px;
}
.marginTop {
  margin-top: 100px;
}
.componentBodyRow {
  margin-top: 50px;
}

.componentBodyImg {
  height: 350px;
  width: auto;

  border-radius: 55px;
  margin: 0 25px;
}
.txtLeft {
  padding-left: 35px;
}
.bodyTextCol {
  margin-top: 30px;
}
.bodyTextColLeft {
  margin-top: 30px;
}
.componentHeaderText {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}
.componentBodyText {
  font-size: 22px;
  color: #000;
  text-align: justify;
}
.bodyBtnContainer {
  margin-top: 50px;
  margin-left: 0px;
}
.bodyBtnText {
  color: rgb(17, 160, 226);
  padding: 10px 25px;
  font-size: 20px;
  border: 1px solid rgb(17, 160, 226);
  border-radius: 10px;
  transition: all ease 0.5s;
}
.bodyBtnText:hover {
  color: #fff;
  background-color: rgb(17, 160, 226);
  border: 1px solid rgb(17, 160, 226);
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .txtLeft {
    padding-left: 25px;
  }
  .componentBodyImg {
    height: 300px;
    border-radius: 35px;
    margin: 0px;
  }
  .imgLeft {
    margin-left: 35px;
  }
  .bodyBtnContainer {
    margin-top: 30px;
  }
  .componentHeaderText {
    font-size: 24px;
  }
  .componentBodyText {
    font-size: 18px;
  }
}
@media (max-width: 1199px) and (min-width: 1000px) {
  .txtLeft {
    padding-left: 35px;
  }
  .componentBodyImg {
    height: 280px;
    border-radius: 25px;
    margin: 0px;
  }
  .imgLeft {
    margin-left: 30px;
  }
  .componentHeaderText {
    font-size: 22px;
  }
  .componentBodyText {
    font-size: 17px;
  }
  .bodyBtnContainer {
    margin-top: 30px;
  }
  .bodyBtnText {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 8px;
  }
}

@media (max-width: 999px) {
  .componentBodyContainer {
    margin-bottom: 50px;
  }
  .txtLeft {
    padding-left: 0px;
  }
  .componentBodyImgContainer {
    margin: 0 auto;
  }
  .componentBodyImg {
    height: 250px;
    border-radius: 15px;
    margin: 0px auto;
  }
  .imgLeft {
    margin-left: 0px;
  }
  .marginTop {
    margin-top: 50px;
  }
  .bodyBtnContainer {
    margin: 20px 0;
  }
  .bodyBtnText {
    font-size: 14px;
  }
}
