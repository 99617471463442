.allProductHeader {
  color: black;

  font-size: 40px;
}
.firstMargin {
  margin-top: 30px;
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .allProductHeader {
    font-size: 30px;
  }
  .firstMargin {
    margin-top: 20px;
  }
}

@media (max-width: 1199px) and (min-width: 1000px) {
  .allProductHeader {
    font-size: 25px;
  }
  .firstMargin {
    margin-top: 15px;
  }
}

@media (max-width: 999px) {
  .allProductHeader {
    font-size: 20px;
  }
  .firstMargin {
    margin-top: 10px;
  }
}
