.headerContainer {
  display: flex;
  padding: 0.75rem 1rem;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.headerFixedContainer {
  transition: all ease 0.18s;
  display: flex;
  padding: 0.75rem 1rem;
  background-color: #2c3f6b;
  position: fixed;
  width: 100%;
  z-index: 2;
}
.navbar {
  width: 100%;
}
.img {
  height: 65px;
}
.imgContainer {
  align-self: center;
}
.searchBar {
  margin-top: 0rem !important;
}
.searchIcon {
  margin-top: 2rem !important;
}
.searchBoxContainer {
  position: fixed;
  margin-top: -240px;
  z-index: -1;
  width: 100%;
  height: 40px;

  background-color: transparent;
  transition: all ease 0.3s;
}
.searchBoxContainerActive {
  position: fixed;
  margin-top: 95px;
  z-index: 3;
  width: 100%;

  background-color: transparent;
  transition: all ease 0.4s;
}
.blankContainer {
  height: 100vh;
  background-color: transparent;
  transition: all ease 0.5s;
}
.blankContainerActive {
  height: 100vh;
  background-color: black;
  opacity: 0.7;
  transition: all ease 0.5s;
}

.formGroup {
  position: fixed;
  display: flex;
  right: 125px;
  top: 60px;
  z-index: 2;
}
.formGroup ul {
  display: flex;
}
.formGroup ul li {
  font-size: 17px;
  color: #fff;
  text-align: center;
  list-style: none;
  width: 25px;
  margin: 0 10px;
  cursor: pointer;
}
.formGroup ul li::after {
  content: "";
  width: 0;
  height: 2px;
  margin: auto;
  background: #0bf7f7;
  display: block;
  transition: 0.5s all;
}
.formGroup ul li:hover::after {
  width: 100%;
}
.searchTimesIcon {
  position: absolute;
  top: 25px;
  right: 60px;
  font-size: 22px;
  cursor: pointer;
}
/* MAC */
@media (max-width: 1600px) and (min-width: 1226px) {
  .img {
    height: 60px;
  }
  .searchIcon {
    margin-top: 1.6rem !important;
  }
  .searchBoxContainer {
    margin-top: -130px;

    height: 40px;
  }
  .searchBoxContainerActive {
    margin-top: 75px;
    z-index: 1;
    width: 100%;
    height: 40px;
  }
  .searchBar.searchBar {
    margin-right: 20px;
    margin-top: -20px;
  }
  .languageText.languageText {
    font-size: 18px;

    margin: auto 13px;
  }
  .btbText.btbText {
    font-size: 18px;
    padding-top: 15%;
  }

  .searchTimesIcon {
    top: 18px;
    right: 25px;
  }
  .formGroup ul li {
    font-size: 13px;
  }
  .formGroup {
    right: 80px;
    top: 49px;
    z-index: 2;
  }
}

/* SEARCH BOX */
@media (max-width: 1225px) and (min-width: 901px) {
  .searchBoxContainer {
    margin-top: -130px;
    height: 30px;
  }
  .img {
    height: 55px;
  }
  .searchBoxContainerActive {
    margin-top: 74px;
    z-index: 1;
    width: 100%;
    height: 30px;
  }
  .formGroup ul li {
    font-size: 13px;
  }
  .formGroup {
    right: 110px;
    top: 48px;
    z-index: 2;
  }
  .btbText.btbText {
    font-size: 18px;
    padding-top: 17%;
    padding-left: 10px;
  }
}
@media (max-width: 900px) {
  .searchBoxContainerActive {
    margin-top: 65px;
    z-index: 1;
    width: 100%;
    height: 30px;
  }
  .btbText.btbText {
    font-size: 16px;
    padding-top: 9%;
    padding-left: 20px;
  }
}

/* LOGO */
@media (max-width: 1225px) {
  .img {
    height: 40px;
  }

  .languageText.languageText.languageText {
    font-size: 15px;
  }
}
@media (max-width: 359px) {
  .img {
    height: 30px;
  }
  .imgContainer {
    margin-left: -20px;
    margin-top: 10px;
  }
  .languageText.languageText.languageText {
    font-size: 14px;
  }
}
@media (max-width: 1000px) {
  .searchBar.searchBar {
    margin-right: 0 !important;
    margin-top: -1.3rem !important;
  }
  .languageText.languageText {
    font-size: 20px;

    margin: auto 10px;
  }

  .formGroup ul li {
    font-size: 13px;
  }
  .formGroup {
    right: 100px;
    top: 55px;
    z-index: 2;
  }
  .formGroup ul {
    display: block;
    padding-left: 8px;
    background-color: rgb(17, 160, 226);
  }
  .formGroup ul li {
    width: 0;
    margin-right: 25px;

    text-align: start;
  }
}
@media (max-width: 361px) {
  .btbText.btbText {
    font-size: 16px;
    padding-top: 4%;
    padding-left: 20px;
  }
}
.searchBar {
  margin-right: 50px;
  margin-top: -25px;
  cursor: pointer;
  display: flex;
}
.languageText {
  font-size: 20px;
  color: #fff;
  margin: auto 15px;
}
.btbText {
  font-size: 20px;
  width: 100px;
  color: #fff;
  padding-top: 20%;
}
.btbText::after {
  content: "";
  width: 0;
  height: 2px;
  margin: -20px auto;

  background: #0bf7f7;
  display: block;
  transition: 0.5s all;
}

.btbText:hover::after {
  width: 100%;
}
.btbText:hover {
  color: #fff;
}
.languageFullContainer {
  position: relative;
}
.languageSelectorEmpty {
  height: 100vh;
  position: fixed;
  width: 100%;
  background: transparent;

  z-index: 0;
}
.searchIcon {
  margin: auto 15px;
  color: #fff;
  font-size: 30px;
}
.text {
  color: #fff;
  font-size: 25;
  padding-right: 20px;
}
