.conditiBodyContainer {
  padding: 20px;
}
.conditiHeaderText {
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.conditiSubHeaderText {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.conditiBodyText {
  font-size: 19px;
  color: #000;
  text-align: justify;
}
.conditiMadde {
  margin-top: 10px;
}

@media (max-width: 1600px) and (min-width: 1400px) {
  .conditiBodyContainer {
    padding: 0px;
  }
  .conditiHeaderText {
    font-size: 18px;
  }
  .conditiSubHeaderText {
    font-size: 18px;
  }
  .conditiBodyText {
    font-size: 17px;
  }
}
@media (max-width: 1399px) and (min-width: 1000px) {
  .conditiBodyContainer {
    padding: 0px;
  }
  .conditiHeaderText {
    font-size: 17px;
  }
  .conditiSubHeaderText {
    font-size: 16px;
  }
  .conditiBodyText {
    font-size: 15px;
  }
}

@media (max-width: 999px) {
  .conditiBodyContainer {
    padding: 0px;
  }
  .conditiHeaderText {
    font-size: 16px;
  }
  .conditiSubHeaderText {
    font-size: 15px;
  }
  .conditiBodyText {
    font-size: 14px;
  }
}
