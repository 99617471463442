.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: #2c3f6b;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: #2c3f6b;
  border: 2px solid #2c3f6b;
}
@keyframes movebtn {
  0% {
    transform: translateY(20px);
  }
  25% {
    transform: translateY(0px);
  }
}
@media (max-width: 1500px) and (min-width: 1000px) {
  .icon-position {
    bottom: 20px;
  }
  .icon-style {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 999px) {
  .icon-position {
    bottom: 15px;
  }
  .icon-style {
    height: 35px;
    width: 35px;
  }
}
