.productFullContainer {
  margin-bottom: 30px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);
  width: 650px;
}
.productColContainer {
  display: flex;
  padding: 5px;
  width: 600px;
}
.productTextContainer {
  margin-top: 20px;
  padding-left: 20px;
}
.modalProductContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalProductImg {
  width: 650px;
  height: auto;
}
.productTextHeader {
  font-size: 22px;
}
.productAciklamaText {
  margin-top: -20px;
  padding-left: 10px;
  font-size: 17px;
}
.productAmountText {
  margin-top: 10px;
  font-size: 17px;
  padding-left: 8px;
}
.productImg {
  height: 200px;
  cursor: pointer;
  object-fit: cover;
  width: 270px;
  border-radius: 25px;
  margin: 0 auto;
}
.accessoriesFullContainer {
  margin: 2px 50px;
}
.accessoriesTypeName {
  margin-top: 10px;
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.accessoriesProductImg {
  height: 120px;
  width: auto;
  margin-bottom: 15px;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
}
.accesssoriesProductTextHeader {
  font-size: 16px;
}
.accessoriesProductTextContainer {
  padding-left: 20px;
}
.accessoriesProductAmountText {
  padding-left: 10px;
}
.accesssoriesAmountText {
  margin-top: -10px;
}
@media (max-width: 1400px) and (min-width: 1000px) {
  .productFullContainer {
    margin-bottom: 30px;
    width: 450px;
  }
  .productColContainer {
    width: 500px;
  }
  .productImg {
    height: 120px;
    width: auto;
  }
  .productTextContainer {
    margin-top: 18px;
    padding-left: 15px;
  }
  .productTextHeader {
    font-size: 16px;
  }
  .productAciklamaText {
    margin-top: -20px;
    font-size: 15px;
  }
  .productAmountText {
    margin-top: 10px;
    font-size: 15px;
  }
  .modalProductImg {
    width: 500px;
    height: auto;
  }
  .accessoriesProductTextContainer {
    padding-left: 10px;
  }
  .accesssoriesProductTextHeader {
    font-size: 14px;
  }
  .accessoriesTypeName {
    font-size: 16px;
  }
}
@media (max-width: 999px) {
  .productFullContainer {
    margin-bottom: 28px;
    width: 98%;
  }
  .productColContainer {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .productImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .productImg {
    height: 150px;
    width: auto;
  }
  .productTextContainer {
    margin-top: 17px;
    padding-left: 14px;
  }
  .productTextHeader {
    font-size: 18px;
    text-align: center;
    padding-left: 0px;
  }
  .productAciklamaText {
    margin-top: -20px;
    padding-left: 0px;
    font-size: 13px;
    text-align: center;
  }
  .productAmountText {
    margin-top: 10px;
    padding-left: 0px;
    font-size: 15px;
    text-align: center;
  }
  .modalProductImg {
    width: 100%;
    height: auto;
  }
  .accessoriesFullContainer {
    margin: 2px 20px;
  }
  .accesssoriesProductTextHeader {
    font-size: 13px !important;
  }
  .accessoriesTypeName {
    font-size: 14px;
  }
  .rangeProductMobile {
    display: inline-block !important;
    margin-left: 50%;
    margin-right: 50%;
    transform: translate(-50%, 0);
  }
  .accRangeProduct {
    display: inline-block !important;
    flex-direction: column-reverse;
    margin-left: 50%;
    margin-right: 50%;
    transform: translate(-50%, 0);
  }
  .accessoriesProductTextContainer {
    padding: 0px;
    text-align: center;
  }
  .accessoriesProductAmountText {
    padding-left: 0px;
  }
}
