.container {
  background-image: url("../../../assets/icons/premium-plus.png");

  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  position: relative;
}

/* MAC */
@media (max-width: 1600px) and (min-width: 1226px) {
  .textContainer {
    padding-top: 70px;
  }
}

@media (max-width: 1000px) {
  .textContainer {
    padding-top: 50px;
  }
}

.textContainer {
  display: flex;
  padding-top: 100px;
}
.infoContainer {
  position: relative;
}
