.container {
  margin-top: 20px;
}

.row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bodyHeaderText {
  font-size: 54px;
  padding-bottom: 15px;
  justify-content: center;
}

.borderTop {
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid #000;
}
.bodyTextContainer {
  padding-left: 60px;
}
.sinceText {
  color: #000;
  font-size: 38px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.bodyImg {
  height: 500px;
  width: auto;
}
.bodyBodyText {
  font-size: 28px;
  text-align: justify;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* Padding-Horizantal */
@media (min-width: 1601px) {
  .bodyHeadContainer {
    padding: 0 190px;
  }
  .bodyBodyContainer {
    max-width: 100%;
  }
  .bodyBodyText {
    font-size: 33px;
  }
  .sinceText {
    font-size: 44px;
  }
  .bodyTextContainer {
    padding-left: 20px;
  }
}
@media (max-width: 1530px) and (min-width: 1300px) {
  .bodyHeadContainer {
    padding: 0px 12px;
  }
  .bodyBodyContainer {
    max-width: 100%;
  }
  .bodyTextContainer.bodyTextContainer {
    padding-left: 0px;
  }
}
@media (max-width: 1299px) and (min-width: 1000px) {
  .bodyHeadContainer {
    padding: 0px 50px;
  }
  .bodyBodyContainer {
    max-width: 100%;
  }
}
/* Body Text İmg padding*/
@media (max-width: 1700px) and (min-width: 1601px) {
  .bodyTextContainer {
    padding-left: 120px;
  }
  .bodyBodyText {
    font-size: 28px;
  }
  .sinceText {
    font-size: 40px;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .bodyTextContainer {
    padding-left: 20px;
  }
  .bodyBodyText {
    font-size: 23px;
    padding-right: 25px;
  }
  .bodyHeaderText {
    font-size: 40px;
    padding-bottom: 6 px;
  }
  .bodyImg {
    height: 370px;
    width: auto;
    margin-left: 20px;
  }
  .sinceText {
    font-size: 30px;
  }
}

@media (max-width: 1199px) and (min-width: 901px) {
  .bodyBodyText {
    font-size: 20px;
  }
  .bodyHeaderText {
    font-size: 45px;
  }
  .bodyImg {
    height: 300px;
    width: auto;
  }
  .bodyTextContainer {
    padding-left: 70px;
  }
  .sinceText {
    font-size: 25px;
  }
}

@media (max-width: 900px) and (min-width: 767px) {
  .bodyBodyText {
    font-size: 18px;
    padding-right: 25px;
  }
  .bodyHeaderText {
    font-size: 35px;
    padding-bottom: 3px;
  }
  .sinceText {
    font-size: 23px;
  }
  .bodyImg {
    height: 300px;
    width: auto;
  }
  .bodyTextContainer {
    padding-left: 70px;
  }
}

@media (max-width: 765px) {
  .bodyBodyText {
    font-size: 18px;
    padding-right: 25px;
  }
  .bodyHeaderText {
    font-size: 33px;
    padding-bottom: 3px;
  }
  .sinceText {
    font-size: 23px;
  }
  .bodyImg {
    height: 300px;
    width: auto;
  }
  .bodyTextContainer {
    padding-left: 0px;
  }
}

/* Header Center */
@media (max-width: 423px) {
  .bodyHeaderText {
    font-size: 25px;
    padding-bottom: 3px;
    text-align: center;
  }
}
