.productRangeHeadContainer {
  background-size: cover;

  height: 50vh;
  width: 100%;
  position: relative;
}

/* header background img */
.malemineContainer {
  background-image: url("/src/assets/icons/malemine.png");
  background-position: center;
}
.camperContainer {
  background-image: url("/src/assets/icons/camper.png");
  background-position: center;
}
.caravanContainer {
  background-image: url("/src/assets/icons/love-caravan.png");
  background-position: top;
}
.slipContainer {
  background-image: url("/src/assets/icons/non-slip.png");
  background-position: center;
}
.premiumContainer {
  background-image: url("/src/assets/icons/premium.png");
  background-position: center;
}
.pPlusContainer {
  background-image: url("/src/assets/icons/premium-plus.png");
  background-position: center;
}
.valueContainer {
  background-image: url("/src/assets/icons/value.png");
  background-position: center;
}
.childrenContainer {
  background-image: url("/src/assets/icons/children.png");
  background-position: center;
}
.polyContainer {
  background-image: url("/src/assets/icons/poly.png");
  background-position: center;
}
.acrylicContainer {
  background-image: url("/src/assets/icons/acrylic.png");
  background-position: center;
}
.othersContainer {
  background-image: url("/src/assets/icons/accessories.png");
  background-position: top;
}
.allProductContainer {
  background-image: url("/src/assets/icons/accessories.png");
  background-position: top;
}

.productRangeHeadTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.productRangeHeadText {
  color: #fff;
  font-size: 56px;
  text-shadow: 2px 2px #000;
}

@media (max-width: 1550px) and (min-width: 1300px) {
  .productRangeHeadText {
    font-size: 48px;
  }
}

@media (max-width: 1299px) and (min-width: 1100px) {
  .productRangeHeadText {
    font-size: 40px;
  }
}

@media (max-width: 1099px) and (min-width: 900px) {
  .productRangeHeadText {
    font-size: 30px;
  }
}

@media (max-width: 899px) {
  .productRangeHeadText {
    margin-top: 35px;
    font-size: 25px;
  }
  .productRangeHeadContainer {
    height: 30vh;
  }
}
