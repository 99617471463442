.pageHeadText {
  font-size: 28px;
  color: #000;
  font-weight: 600;
  padding-left: 25px;
}
.pageTextContainer {
  list-style: none;
  margin: 5px 15px;
}
.pageText {
  font-size: 18px;
  color: #000;
  transition: all ease 0.35s;
}
.pageText:hover {
  color: rgb(17, 160, 226);
}
