.borderTop {
  width: 200px;
  margin: 20px auto;
  border-top: 3px solid #000;
}
.consultContainer {
  margin-top: 0px;
}
.consultText {
  color: #000;
  font-size: 54px;
}
.contactHeader {
  font-size: 26px;
  font-weight: bold;
  padding-left: 60px;
  margin-bottom: 20px;
}
.infoItemContainer {
  display: flex;
  width: 75%;
  border-bottom: 1px solid #ccc;
  margin: 0 auto;
  padding: 5px 0 20px 0;

  margin-bottom: 20px;
  transition: all ease 0.5s;
}
.contactImgContainer {
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ccc;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoItemImg {
  font-size: 50px;
  transition: all ease 0.5s;

  padding: 10px;
}

.infoItemText {
  color: #000;
  font-size: 17px;
  margin: auto 0px;
}
.verticalBorder {
  height: 350px;
  margin: 120% 0px;
  width: 100%;
  border-left: #000 2px solid;
}
.contacFormGroup {
  display: flex;
  justify-content: space-between;
}
.formControl:focus {
  border: #ccc 1px solid;
  box-shadow: none;
}
.formName {
  width: 55%;
}
.formTel {
  width: 40%;
}
.contactSendBtn {
  padding: 7px 25px;
}
.contactFormText {
  color: #000;
}

@media (max-width: 1300px) and (min-width: 993px) {
  .consultText {
    font-size: 40px;
  }
  .infoItemText {
    font-size: 13px;
  }
  .contactImgContainer {
    height: 44px;
    width: 44px;
  }
  .infoItemImg {
    font-size: 40px;
  }
  .infoItemContainer {
    margin-bottom: 10px;
  }
  .contactFormText {
    font-size: 13px;
  }
  .contactHeader {
    font-size: 21px;

    padding-left: 70px;
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .consultText {
    font-size: 34px;
  }
  .infoItemContainer {
    width: 100%;
  }
  .contactImgContainer {
    margin-right: 15px;
    margin-left: 0px;
  }
  .infoItemText {
    font-size: 14px;
  }
  .contactFormText {
    font-size: 13px;
  }
  .contactHeader {
    font-size: 20px;
    font-weight: bold;
    padding-left: 0px;
    margin-bottom: 10px;
  }
}
