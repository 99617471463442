.becomeFormText {
  color: #000;
}
.becomeHeadText {
  color: #000;
  font-size: 54px;
}
.becomeFormGroup {
  width: 600px;
}

@media (max-width: 1300px) {
  .becomeFormText {
    font-size: 13px;
  }
  .becomeHeadText {
    font-size: 40px;
  }
  .becomeFormGroup {
    width: 400px;
  }
}
@media (max-width: 992px) {
  .becomeHeadText {
    font-size: 28px;
  }
  .becomeFormGroup {
    width: 100%;
  }
}
