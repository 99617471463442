nav {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
.nav-links {
  flex: 1;

  display: flex;
}
.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 8px 14px;
  position: relative;
}
.nav-links ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.nav-links ul li::after {
  content: "";
  width: 0;
  height: 2px;
  margin: auto;
  background: #0bf7f7;
  display: block;
  transition: 0.5s all;
}
.nav-links ul li:hover::after {
  width: 100%;
}
.navbarMenu {
  padding-top: 1rem;
  padding-left: 5rem;
}
.dropdownMenu {
  position: relative;
  display: inline-block;
}
.dropdownMenu2 {
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  left: -30px;
  top: 40px;
  background-color: rgb(17, 160, 226);
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content2 {
  display: none;
  position: absolute;
  left: 230px;
  top: -20px;
  background-color: rgb(17, 160, 226);
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 8px;
  text-decoration: none;
  display: block;
}
.dropdown-content a::after {
  content: "";
  width: 0;
  height: 2px;
  margin: auto;
  background: #0bf7f7;
  display: block;
  transition: 0.5s all;
}
.dropdown-content a:hover::after {
  width: 100%;
}
.dropdown-content2 a {
  color: black;
  padding: 12px 8px;
  text-decoration: none;
  display: block;
}
.dropdown-content2 a::after {
  content: "";
  width: 0;
  height: 2px;
  margin: auto;
  background: #0bf7f7;
  display: block;
  transition: 0.5s all;
}
.dropdown-content2 a:hover::after {
  width: 100%;
}
.dropdownMenu:hover .dropdown-content {
  display: block;
}
.dropdownMenu2:hover .dropdown-content2 {
  display: block;
}
.text {
  cursor: pointer;
  color: #fff;
}
nav .timesIcon {
  display: none;
}
nav .barsIcon {
  display: none;
}
@media (max-width: 1550px) and (min-width: 1165px) {
  .nav-links ul li a {
    font-size: 14px;
    font-weight: normal;
  }
  nav {
    padding: 0 5%;
  }
  .nav-links ul li {
    padding: 8px 8px;
  }
  .dropdown-content {
    left: 0px;
    top: 33px;

    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-content2 {
    left: 150px;
    top: -20px;

    min-width: 300px;
    z-index: 1;
  }
  .dropdown-content a {
    padding: 8px 8px;
  }
}
@media (max-width: 1164px) and (min-width: 1051px) {
  .nav-links ul li a {
    font-size: 12.2px;
    font-weight: normal;
  }
  nav {
    padding: 0;
    margin-top: 10px;
  }
  .nav-links ul li {
    padding: 8px 8px;
  }
  .dropdown-content {
    left: 0px;
    top: 33px;

    min-width: 170px;

    z-index: 1;
  }
  .dropdown-content2 {
    left: 150px;
    top: -20px;

    min-width: 170px;
    z-index: 1;
  }
  .dropdown-content a {
    padding: 8px 6px;
  }
}
@media (max-width: 1050px) {
  .nav-links ul li {
    display: block;
  }
  .nav-links {
    position: absolute;
    background: #fff;
    height: 100vh;
    width: 250px;
    top: 0;
    right: 0;
    display: none;
    z-index: 0;
    text-align: left;
    transition: 0.5s all;
    overflow-y: scroll;
  }
  .dropdown-content {
    position: relative;
    background-color: transparent;
    left: -25px;
    top: 0px;
    min-width: 248px;
  }
  .dropdown-content2 {
    margin-right: 5px;
    position: relative;
    background-color: transparent;
    left: -25px;
    top: 0px;
    min-width: 248px;
  }
  .dropdown-content a {
    padding: 8px 2px;
  }
  .dropdown-content2 a {
    padding: 6px 2px;
  }
  .nav-links ul li {
    padding: 8px 2px;
  }
  .nav-links ul li a {
    color: #000;
  }
  nav .timesIcon {
    display: block;
    color: #000;
    font-size: 22px;
    margin: 10px;
    cursor: pointer;
  }
  nav .barsIcon {
    display: block;
    color: #fff;
    font-size: 22px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
  }
  .nav-links ul {
    padding: 30px;
    margin-right: 30px;
  }
}
