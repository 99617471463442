.headerHeader {
  margin-top: 20px;
}
.headerHeaderText {
  font-size: 75px;
  color: #fff;
  text-align: center;
}

.headerBodyText {
  font-size: 40px;
  color: #fff;
  text-align: center;
}
@media (max-width: 1600px) and (min-width: 1100px) {
  .headerHeaderText {
    font-size: 40px;
  }
  .headerTextContainer {
    padding: 0 8%;
  }
  .headerBodyText {
    margin-top: -15px;
    font-size: 23px;
  }
  .headerHeader {
    margin-top: -10px;
  }
}

@media (max-width: 1099px) {
  .headerHeaderText {
    font-size: 30px;
  }
  .headerBodyText {
    font-size: 18px;
  }
  .headerHeader {
    margin-top: 5px;
  }
}
@media (max-width: 800px) {
  .headerHeaderText {
    font-size: 30px;
  }
  .headerBodyText {
    font-size: 15px;
  }
}
